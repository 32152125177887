import React, { useState } from 'react';
import { react2angular } from 'react2angular';
import { Alert, Button } from 'easyship-components';
import { FormattedMessage } from 'react-intl';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import ReactRootProviders from '../../../auth/providers/ReactRootProviders';
import { OtpFormModal } from '../OtpFormModal';

export function EmailUnverifiedBanner() {
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);

  function toggleOtpModal() {
    setIsOtpModalOpen((prevState) => !prevState);
  }

  function handleOnVerify() {
    window.location.reload();
  }

  return (
    <>
      <Alert
        severity="warning"
        className="items-center"
        action={
          <Button onClick={toggleOtpModal}>
            <FormattedMessage id="auth.verify-now" />
          </Button>
        }
      >
        <FormattedMessage id="auth.verify-email" />
      </Alert>
      <OtpFormModal
        context="banner"
        onVerify={handleOnVerify}
        onAskLaterClicked={toggleOtpModal}
        isOpen={isOtpModalOpen}
      />
    </>
  );
}

export function WrappedEmailUnverifiedBanner() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <EmailUnverifiedBanner />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularEmailUnverifiedBanner = react2angular(WrappedEmailUnverifiedBanner, []);
