import { useMutation } from '@tanstack/react-query';
import { checkEmailVerification } from '@client/src/verification/api';
import { CheckEmailVerificationPayload } from '../models/payload/verification-payload';

interface CheckVerificationParams {
  payload: CheckEmailVerificationPayload;
}

export default function useCheckVerificationMutation() {
  return useMutation({
    mutationFn: ({ payload }: CheckVerificationParams) => checkEmailVerification(payload),
  });
}
