import { ModalContainerProps } from '@app/components/ModalContainer/types';

export enum EmailPreviewModalType {
  LabelGenerated = 'labelGenerated',
  PickupConfirmed = 'pickupConfirmed',
  SenderSurvey = 'senderSurvey',
}

export interface EmailPreviewModalDataProps {
  type: EmailPreviewModalType;
}

export type EmailPreviewModalProps = Pick<ModalContainerProps, 'isOpen' | 'handleClose'> &
  EmailPreviewModalDataProps;
