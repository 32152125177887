import { toastError } from '@/components/Toastify';
import { AxiosError } from 'axios';
import { ApiResponseError, ApiResponseErrorWithCodeAndMessage } from '@/types/api';

const isApiResponseError = (error: unknown): error is ApiResponseError => {
  const errors = (error as Record<'response', { data?: { errors: unknown } }>)?.response?.data
    ?.errors;
  return Array.isArray(errors) && errors?.every((a) => typeof a === 'string');
};

const isApiResponseErrorWithCodeAndMessage = (
  error: unknown
): error is ApiResponseErrorWithCodeAndMessage => {
  const apiResponseError = (
    error as Record<
      'response',
      {
        data?: { error?: unknown };
      }
    >
  )?.response?.data?.error as Partial<ApiResponseErrorWithCodeAndMessage>;

  const hasMessage = 'message' in apiResponseError && typeof apiResponseError.message === 'string';
  const hasCode = 'code' in apiResponseError && typeof apiResponseError.code === 'string';
  return hasCode && hasMessage;
};

export const toastApiError = (error: ApiResponseError | AxiosError) => {
  const message = (() => {
    if (typeof error.response?.data === 'string') return error.response?.data;
    if (isApiResponseErrorWithCodeAndMessage(error)) return error.response?.data.error.message;
    if (isApiResponseError(error)) {
      return error.response?.data?.errors?.join('');
    }
    return error.message;
  })();
  toastError(message);
};
