import { useMutation } from '@tanstack/react-query';
import { fetchUserSessionData } from '@/api/user';
import { ApiResponseError } from '@app/types/api';
import { AxiosError } from 'axios';
import { toastApiError } from '@app/utils/api';
import { IUserSession } from '@typings/user-session';
import angular from 'angular';

export const useUpdateUserSessionMutation = () => {
  return useMutation({
    mutationFn: () => fetchUserSessionData(),
    onError: (error: AxiosError<ApiResponseError>) => toastApiError(error),
    onSuccess: (data) => {
      const service: IUserSession = angular.element(document.body).injector().get('UserSession');
      service.updateCompany(data.company);
      service.updateUser(data.user);
    },
  });
};
