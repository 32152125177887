import { IUserSession } from 'typings/user-session';
import { IUserStatusService } from 'typings/user-status';
import { PaymentSourceService } from '@client/src/global/services/payment-source/payment-source.service';
import { ICreditCardData } from 'typings/payment-source';
import { AutoRechargeService } from '@client/src/global/services/auto-recharge/auto-recharge.service';
import { IComponentController } from 'angular';
import template from './payment-balance.html?raw';
import style from './payment-balance.module.scss';

class PaymentBalance implements IComponentController {
  style = style;

  static $inject = [
    'UserStatusService',
    'UserSession',
    'PaymentSourceService',
    'AutoRechargeService',
  ];
  constructor(
    private UserStatusService: IUserStatusService,
    private UserSession: IUserSession,
    private PaymentSourceService: PaymentSourceService,
    private AutoRechargeService: AutoRechargeService
  ) {}

  get hasPositiveBalance(): boolean {
    if (
      this.UserStatusService.availableBalance === null ||
      this.UserStatusService.availableBalance === undefined
    ) {
      return false;
    }

    return this.UserStatusService.availableBalance >= 0;
  }

  get hasNegativeBalance(): boolean {
    if (!this.UserStatusService.availableBalance) {
      return false;
    }

    return this.UserStatusService.availableBalance < 0;
  }

  get availableBalance(): number {
    return this.UserStatusService.availableBalance || 0;
  }

  get currency(): string {
    return this.UserSession.getCompanyCurrency();
  }

  get disableRefundButton(): boolean {
    if (
      this.UserStatusService.availableBalance === null ||
      this.UserStatusService.availableBalance === undefined
    ) {
      return true;
    }

    return this.UserStatusService.availableBalance.toFixed(2) === '0.00' || this.hasNegativeBalance;
  }

  get isAchEnabled(): boolean {
    return this.PaymentSourceService.isAchEnabled;
  }

  get hasDefaultPaymentMethod(): boolean | undefined {
    if (this.PaymentSourceService.haveValidPaymentSource) {
      const defaultCard = this.PaymentSourceService.getDefaultCard() as ICreditCardData;
      return !defaultCard.expired;
    }

    return false;
  }

  get isAutoRechargeOn(): boolean {
    return this.AutoRechargeService.autoRechargeData?.isEnabled || false;
  }

  get autoRechargeThreshold(): string {
    const threshold = this.AutoRechargeService.autoRechargeData?.threshold || 0;
    return threshold.toLocaleString();
  }

  get autoRechargeAmount(): string {
    const amount = this.AutoRechargeService.autoRechargeData?.amount || 0;
    return amount.toLocaleString();
  }

  get last4(): string {
    const defaultCard = this.PaymentSourceService.getDefaultCard() as ICreditCardData;
    return defaultCard?.last4 || '';
  }
}

const PaymentBalanceComponent: ng.IComponentOptions = {
  controller: PaymentBalance,
  template,
  bindings: {
    esAddCreditsAction: '&',
    esRefundCreditsAction: '&',
    esEditAutoRechageSettings: '&',
    esIsBusyFetching: '<',
  },
};

export { PaymentBalanceComponent };
