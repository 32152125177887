import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '@/providers/UserSessionProvider';
import { updateUser } from '@/api/user';
import { ApiResponseError } from '@app/types/api';
import { AxiosError } from 'axios';
import { toastApiError } from '@app/utils/api';
import { IUser, IUserSession } from '@typings/user-session';
import { TransformToCamelCase } from '@/types/general';
import { useCompanyId } from '@/hooks/general/useCompanyId';
import angular from 'angular';
import { PartialDeep } from 'type-fest';

export const useUserUpdateMutation = () => {
  const { user } = useUserSession();
  const companyId = useCompanyId();
  return useMutation({
    mutationFn: (payload: TransformToCamelCase<PartialDeep<IUser>>) =>
      updateUser({ userId: user?.id, payload, companyId }),
    onError: (error: AxiosError<ApiResponseError>) => toastApiError(error),
    onSuccess: (data) => {
      const service: IUserSession = angular.element(document.body).injector().get('UserSession');
      const printingSettings = data?.user?.printing_settings;
      if (printingSettings) {
        if (service) {
          service.updateUserPrintingSettings(printingSettings);
        }
      }
      if (typeof data?.user?.dashboard_settings?.mfa?.enabled === 'boolean') {
        service.updateUser(data?.user);
      }
    },
  });
};
