import React from 'react';
import { react2angular } from 'react2angular';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { SecurityCard } from '@app/pages/profile/components/cards/SecurityCard';

function WrappedComponent() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <SecurityCard />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularSecurityCard = react2angular(WrappedComponent);
