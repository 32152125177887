import React from 'react';
import { react2angular } from 'react2angular';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { CourierSurveyCard } from '@app/pages/profile/components/cards/CourierSurveyCard';

function WrappedComponent() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <CourierSurveyCard />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularCourierSurveyCard = react2angular(WrappedComponent);
