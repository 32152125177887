export const SignupStateName = 'auth.signup';
export const IncompleteStateName = 'auth.signup.incomplete';
export const LeadStateName = 'auth.signup.lead';
export const EasyshipLeadStateName = 'auth.signup.lead.easyship';
export const FulfillmentLeadStateName = 'auth.signup.lead.fulfillment';
export const PlatformLeadStateName = 'auth.signup.lead.platform';
export const PlatformAuthStateName = 'auth.signup.platform-auth';
export const RegistrationFlowStateName = 'auth.signup.registration-flow';
// Key should match the backend api step keys
export enum RegistrationFlowStep {
  VerifyOtp = 'verify-otp',
  User = 'user',
  Company = 'company',
  Address = 'address',
  CourierAccount = 'courier-account',
  Settings = 'settings',
  Drivers = 'drivers',
}
export const MobileTips = 'auth.mobile-tips';

export enum InvalidStep {
  Country = 'invalid-country',
}

export enum StateParamsPlatform {
  Website = 'website',
  Fulfillment = 'fulfillment',
  SkyPostal = 'skypostal',
  ParcelCast = 'parcelcast',
  PostageForce = 'postageforce',
  Packifi = 'packifi',
  Dashboard = 'signup',
  Shopify = 'shopify',
  BigCommerce = 'bigcommerce',
  TradeGecko = 'tradegecko',
  Weebly = 'weebly',
  Cashier = 'cashier',
  Ecwid = 'ecwid',
  EcwidPrivate = 'ewl_private',
  Square = 'square',
  Kickstarter = 'kickstarter',
  Indiegogo = 'indiegogo',
  // for the DUDA platform, they don't want to show their name on the URL so that we use this number.
  Duda = '4137167',
  UberFreight = 'uber-freight',
  TikTok = 'tiktok',
  Shopline = 'shopline',
  Labelgo = 'labelgo',
}
