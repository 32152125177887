import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { IUserNotificationSettings } from '@typings/user-session';
import { useUserSession } from '@/providers/UserSessionProvider';
import { GET_USER_NOTIFICATION_SETTINGS_QUERY } from '@app/hooks/queries/user/useGetNotificationSettingsQuery/types';
import { getNotificationSettings } from '@app/api/user';
import { snakeToCamelCase } from '@app/utils/snakeCamelTransform';
import { CamelCasedPropertiesDeep } from 'type-fest';

export function useGetNotificationSettingsQuery(
  params?: UseQueryOptions<CamelCasedPropertiesDeep<IUserNotificationSettings>>
) {
  const { user } = useUserSession();

  return useQuery({
    queryKey: [GET_USER_NOTIFICATION_SETTINGS_QUERY],
    queryFn: async () => snakeToCamelCase(await getNotificationSettings(user.id)),
    ...params,
  });
}
