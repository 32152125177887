import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Alert, Button } from 'easyship-components';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import Link from '@client/core/components/react/Link';
import z from 'zod';
import { toastSuccess } from '@client/core/components/react/Toastify';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { react2angular } from 'react2angular';
import { AxiosError } from 'axios';
import { OtpModalProps } from '@client/src/verification/components/OtpFormModal/types';
import OtpForm from '../OtpForm';
import useCheckVerificationMutation from '../../hooks/useCheckVerificationMutation';
import useSendOtpEmailMutation from '../../hooks/useSendOtpEmailMutation';
import { OtpFormData } from '../../models/payload/verification-payload';
import { ErrorData, OtpData } from '../../models/data/verification-data';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type FormField = keyof OtpFormData;
type FieldErrors = Partial<Record<FormField, string>>;

export function OtpFormModal({
  isOpen,
  onAskLaterClicked,
  defaultData,
  onVerify,
  context,
}: OtpModalProps) {
  const { formatMessage } = useIntl();
  const { user } = useUserSession();

  const { mutateAsync: checkVerificationMutateAsync, isLoading } = useCheckVerificationMutation();

  const { mutateAsync: sendOtpEmailMutateAsync, isLoading: isSendLoading } =
    useSendOtpEmailMutation();

  const otpFormRef = useRef<HTMLFormElement>(null);

  const [errors, setErrors] = useState<FieldErrors>({});

  const [resendSecondsLeft, setResendSecondsLeft] = useState(() => {
    const now = new Date();
    const resendAllowedAt = window.localStorage.getItem('resendAllowedAt');
    if (resendAllowedAt) {
      const timeRemaining = new Date(resendAllowedAt).getTime() - now.getTime();
      const secondsRemaining = Math.floor(timeRemaining / 1000);
      return secondsRemaining;
    }
    return 0;
  });

  const otpFormSchema = z
    .object({
      code: z
        .string()
        .min(6, {
          message: formatMessage({ id: 'auth.otp-modal.six-digit-validation' }),
        })
        .max(6, {
          message: formatMessage({ id: 'auth.otp-modal.six-digit-validation' }),
        }),
    })
    .required();

  useEffect(() => {
    const interval = setInterval(() => {
      setResendSecondsLeft((prevState) => {
        return prevState - 1;
      });
    }, 1000);

    if (resendSecondsLeft < 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [resendSecondsLeft]);

  useEffect(() => {
    const now = new Date();
    let isExpired = true;
    const expiryTime = window.localStorage.getItem('expiresAt');

    if (expiryTime) {
      isExpired = new Date(expiryTime) < now;
    }
    if (isOpen && isExpired) {
      sendOtpEmailMutateAsync({ payload: { context } })
        .then((res: OtpData) => {
          window.localStorage.setItem('expiresAt', res.expires_at);
          window.localStorage.setItem('resendAllowedAt', res.resend_allowed_at);
          setResendSeconds(res.resend_allowed_at);
        })
        .catch((err) => {
          setResendSeconds(err.response.data.resend_allowed_at);
        });
    }
  }, [context, isOpen, sendOtpEmailMutateAsync]);

  function verifyOtp(): void {
    if (otpFormRef.current) {
      const data = new FormData(otpFormRef.current).entries();
      const formData = Object.fromEntries(data);

      const otpData = otpFormSchema.safeParse(formData);

      if (otpData.success) {
        const otpFormData: OtpFormData = {
          code: otpData.data.code,
          context,
        };

        setErrors({});

        checkVerificationMutateAsync({ payload: otpFormData })
          .then(() => {
            toastSuccess(formatMessage({ id: 'auth.otp-modal.verify-success' }));
            window.localStorage.removeItem('resendAllowedAt');
            window.localStorage.removeItem('expiresAt');
            setTimeout(() => {
              onVerify?.();
            }, 1000);
          })
          .catch((err: AxiosError<ErrorData>) => {
            setErrors({
              code: err.response?.data?.error
                ? err.response.data.error.message
                : formatMessage({ id: 'toast.default-error' }),
            });
          });
      } else {
        const newErrors: FieldErrors = {
          code: otpData.error.formErrors.fieldErrors.code?.[0] ?? undefined,
        };
        setErrors(() => {
          return newErrors;
        });
      }
    }
  }

  function handleOnAskLaterClicked(): void {
    setErrors({});
    onAskLaterClicked();
  }

  function handleRedirectChangeEmail(): void {
    window.location.pathname = '/account/profile';
  }

  function handleResendEmail(): void {
    sendOtpEmailMutateAsync({ payload: { context } })
      .then((res: OtpData) => {
        toastSuccess(formatMessage({ id: 'auth.otp-modal.code-resent' }));
        window.localStorage.setItem('resendAllowedAt', res.resend_allowed_at);
        setResendSeconds(res.resend_allowed_at);
      })
      .catch((err: AxiosError<ErrorData>) => {
        setErrors({ code: err.response?.data.error.message });
      });
  }

  function setResendSeconds(resendAllowedAt: string): void {
    setResendSecondsLeft(() => {
      const now = new Date();
      if (resendAllowedAt) {
        const timeRemaining = new Date(resendAllowedAt).getTime() - now.getTime();
        const secondsRemaining = Math.floor(timeRemaining / 1000);
        return secondsRemaining;
      }
      return 60;
    });
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      TransitionProps={{ timeout: 400 }}
      maxWidth="sm"
      fullWidth
      open={!!isOpen}
    >
      <div className="bg-white rounded-md">
        <div className="py-5 pl-12 pr-5 text-xl leading-7 border-b border-sky-300">
          <FormattedMessage id="auth.otp-modal.title" />
        </div>
        <div className="px-12 py-10 text-base">
          <FormattedMessage id="auth.otp-modal.description" values={{ email: user.email }} />{' '}
          <FormattedMessage id="auth.otp-modal.still-trouble" />{' '}
          <Link
            href={`${
              import.meta.env.VITE_APP_HELP
            }/hc/en-us/articles/30104448372249-Email-Verification`}
            target="_blank"
            className="font-bold text-blue-500"
          >
            <FormattedMessage id="auth.otp-modal.read-article" />
          </Link>
          <div className="mt-5">
            <OtpForm
              error={errors.code || ''}
              onSubmit={verifyOtp}
              ref={otpFormRef}
              defaultData={defaultData}
            />
          </div>
          <div className="mt-3">
            <Button
              onClick={handleResendEmail}
              flat
              disabled={resendSecondsLeft >= 0 || isSendLoading}
              className="gap-0 p-0 text-base font-bold text-blue-500 normal-case cursor-pointer hover:text-blue-700 hover:bg-transparent disabled:text-sky-700"
            >
              <FormattedMessage id="auth.otp-modal.resend-code" />{' '}
              {resendSecondsLeft >= 0 && (
                <>
                  {' '}
                  <FormattedMessage id="global.in" /> {resendSecondsLeft}s
                </>
              )}
              {isSendLoading && (
                <div className="flex items-center justify-center">
                  <svg className="w-3 h-3 ml-2 animate-spin" viewBox="0 0 24 24">
                    <circle
                      className="opacity-0"
                      cx="13"
                      cy="13"
                      r="13"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </div>
              )}
            </Button>
          </div>
          <div className="flex justify-center w-full">
            <Button className="mt-5" onClick={handleRedirectChangeEmail}>
              <FormattedMessage id="auth.otp-modal.use-different-email" />
            </Button>
          </div>
          {errors.code && (
            <Alert severity="error" className="mt-5">
              {errors.code}
            </Alert>
          )}
        </div>

        <div className="flex items-center justify-between w-full px-12 py-5 border-t border-sky-300">
          <Button flat onClick={handleOnAskLaterClicked}>
            {formatMessage({ id: 'global.ask-me-later' })}
          </Button>
          <Button
            className="px-10"
            onClick={verifyOtp}
            color="primary"
            loading={isLoading}
            disabled={isLoading}
          >
            {formatMessage({ id: 'global.verify' })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default OtpFormModal;
