import { API } from '@client/core/config/api.constant';
import { OtpData } from '@client/src/verification/models/data/verification-data';
import {
  CheckEmailVerificationPayload,
  SendOtpEmailPayload,
} from '@client/src/verification/models/payload/verification-payload';
import axios, { AxiosResponse } from 'axios';

export function checkEmailVerification(payload: CheckEmailVerificationPayload): Promise<OtpData> {
  return axios
    .post<OtpData>(`${API.endpoint}/users/verify_email`, payload)
    .then((res: AxiosResponse) => {
      return res.data;
    });
}

export function sendOtpEmail(payload: SendOtpEmailPayload): Promise<OtpData> {
  return axios
    .post<OtpData>(`${API.endpoint}/mfa/email_otp_codes`, payload)
    .then((res: AxiosResponse) => {
      return res.data;
    });
}
