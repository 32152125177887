import { useMutation } from '@tanstack/react-query';
import { sendOtpEmail } from '@client/src/verification/api';
import { SendOtpEmailPayload } from '../models/payload/verification-payload';

interface SendOtpEmailMutationParams {
  payload: SendOtpEmailPayload;
}

export default function useSendOtpEmailMutation() {
  return useMutation({
    mutationFn: ({ payload }: SendOtpEmailMutationParams) => sendOtpEmail(payload),
  });
}
