import { useGetUserSessionQuery } from '@/hooks/queries/useGetUserSessionQuery';
import { UserSession } from '@/hooks/queries/useGetUserSessionQuery/types';
import { IUserDashboardSettings } from '@typings/user-session';
import { TransformToCamelCase } from '@/types/general';

export const useUserDashboardSettings = () => {
  const { data } = useGetUserSessionQuery();
  const { user } = (data || {}) as UserSession;
  return (user?.dashboardSettings || {}) as TransformToCamelCase<IUserDashboardSettings>;
};
