import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import useUpdateFlowRecordsMutation from '@client/src/user/hooks/mutations/useUpdateFlowRecordsMutation';
import { FlowRecordsPayload } from '@client/src/user/models/payloads/user-payload';
import { UserGatewayProvider } from '@client/src/user/providers/UserGatewayProvider';
import { OtpFormModal } from '@client/src/verification/components/OtpFormModal';
import React, { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';

let didInit = false;
export function TemplateBlankComponent() {
  const { user } = useUserSession();
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync: updateFlowRecordsMutateAsync } = useUpdateFlowRecordsMutation();

  function toggleIsOpen(): void {
    setIsOpen((prevState) => !prevState);
  }

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      const isEmailVerificationNeeded =
        user.emailVerificationRequired &&
        !user.emailVerified &&
        !user.hasSeenWalkthrough('display_verify_email_by_otp_modal');

      if (isEmailVerificationNeeded) {
        const payload: FlowRecordsPayload = {
          flowRecord: {
            flowName: 'display_verify_email_by_otp_modal',
            flowType: 'walkthrough',
          },
        };
        updateFlowRecordsMutateAsync({ userId: user.id, payload });
        toggleIsOpen();
      }
    }
  }, [updateFlowRecordsMutateAsync, user, user.emailVerificationRequired, user.emailVerified]);

  return (
    <OtpFormModal
      context="new_session_prompt"
      isOpen={isOpen}
      onAskLaterClicked={toggleIsOpen}
      onVerify={toggleIsOpen}
    />
  );
}

export function WrappedTemplateBlankComponent() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <UserGatewayProvider>
          <TemplateBlankComponent />
        </UserGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularTemplateBlankComponent = react2angular(WrappedTemplateBlankComponent, []);
