/* eslint-disable no-restricted-imports */
import { toastError, toastSuccess } from '@app/components/Toastify';
import { useUserDashboardSettings } from '@app/hooks/general/useUserDashboardSettings';
import { useUpdateUserSessionMutation } from '@app/hooks/mutations/useUpdateUserSessionMutation';
import { useUserUpdateMutation } from '@app/hooks/mutations/useUserUpdateMutation';
import { useGetUserSessionQuery } from '@app/hooks/queries/useGetUserSessionQuery';
import {
  GET_USER_SESSION_QUERY_KEY,
  UserSession,
} from '@app/hooks/queries/useGetUserSessionQuery/types';
import useObserverQuery from '@app/hooks/queries/useObserverQuery';
import { openModal } from '@app/utils/modals';
import { IUser } from '@typings/user-session';
import {
  Card,
  CardContent,
  CardHeader,
  ToggleButton,
  ToggleButtonOption,
} from 'easyship-components';
import React, { useState, useCallback, lazy, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface UpdateUserResponse {
  user: IUser;
}

const OtpFormModal = lazy(() => import('@client/src/verification/components/OtpFormModal'));

export function SecurityCard() {
  const { formatMessage } = useIntl();

  const dashboardSettings = useUserDashboardSettings();
  const { data: userSession, refetch: refetchUserSession } = useGetUserSessionQuery();
  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = useUserUpdateMutation();
  const { mutateAsync: updateUserSession } = useUpdateUserSessionMutation();

  const { data: observedData } = useObserverQuery<UserSession>([GET_USER_SESSION_QUERY_KEY]);

  const modalRef = useRef<ReturnType<typeof openModal>>();

  const [is2FAEnabled, setIs2FAEnabled] = useState(dashboardSettings?.mfa?.enabled);

  const toggleOptions: [ToggleButtonOption<boolean>, ToggleButtonOption<boolean>] = [
    {
      label: formatMessage({ id: 'global.no' }),
      value: false,
    },
    {
      label: formatMessage({ id: 'global.yes' }),
      value: true,
    },
  ];

  const handleUpdateUser = useCallback(
    async (value: boolean): Promise<UpdateUserResponse> => {
      return updateUser({
        dashboardSettings: {
          ...dashboardSettings,
          mfa: {
            enabled: value,
          },
        },
      });
    },
    [dashboardSettings, updateUser]
  );

  const handleOnAskLaterClicked = useCallback((): void => {
    modalRef.current?.close();
    setIs2FAEnabled(false);
  }, []);

  async function handleOnVerify(): Promise<void> {
    try {
      await updateUserSession();
      const { data } = await refetchUserSession();
      handleToggleChange(true, data?.user);
      modalRef.current?.close();
    } catch (error) {
      toastError(error);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleToggleChange(value: boolean, user = userSession?.user) {
    const isVerificationNeeded = user?.emailVerificationRequired && !user?.emailVerified;

    if (value === is2FAEnabled) return;

    if (isVerificationNeeded) {
      modalRef.current = openModal(OtpFormModal, {
        context: 'profile_page_2fa_toggle',
        onAskLaterClicked: handleOnAskLaterClicked,
        onVerify: handleOnVerify,
      });
      return;
    }

    setIs2FAEnabled(value);
    try {
      const response = await handleUpdateUser(value);
      if (response?.user) {
        toastSuccess(formatMessage({ id: 'profile.update-success' }));
      }
    } catch (error) {
      setIs2FAEnabled(!value); // Revert the toggle if update fails
      toastError(error);
    }
  }

  // use query observer to update the state when the query data changes - listening from outside changes too (e.g. change email)
  useEffect(() => {
    if (observedData) {
      setIs2FAEnabled(observedData?.user?.dashboardSettings?.mfa?.enabled ?? false);
    }
  }, [observedData]);

  return (
    <Card variant="normal">
      <CardHeader
        divider
        title={formatMessage({ id: 'profile.security-card.title' })}
        subtitle={formatMessage({ id: 'profile.security-card.description' })}
      />
      <CardContent>
        <div>
          <p className="text-base font-bold">
            <FormattedMessage id="profile.security-card.2fa-title" />
          </p>
          <div className="flex items-center justify-between gap-2">
            <p className="mb-0 text-base text-ink-500">
              <FormattedMessage id="profile.security-card.2fa-description" />
            </p>
            <ToggleButton<boolean>
              disabled={isUpdatingUser}
              onChange={handleToggleChange}
              options={toggleOptions}
              value={is2FAEnabled}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
