import { IUserSession } from 'typings/user-session';

import angular, { IComponentController, IFormController } from 'angular';
import { IUserService } from 'typings/user-service';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { IAuthService } from 'typings/core/services/auth';
import style from './change-email-modal.module.scss';
import template from './change-email-modal.html?raw';

type FormField = 'email' | 'password';

class ChangeEmailModal implements IComponentController {
  style = style;
  error = '';
  translations: Record<string, string> = {};
  loading = false;
  formData: Record<FormField, string> = {
    email: '',
    password: '',
  };
  form: IFormController | undefined;

  static $inject = ['UserSession', 'MixpanelService', 'UserService', '$translate', 'Auth'];
  constructor(
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService,
    private UserService: IUserService,
    private $translate: angular.translate.ITranslateService,
    private Auth: IAuthService
  ) {
    this.formData.email = UserSession.user.email;
  }

  $onInit() {
    this.$translate(['profile.update-email-failed']).then((translations) => {
      this.translations = translations;
    });
  }

  esClose(): void {
    this.esClose();
  }

  onInputChange(value: string, key: FormField) {
    this.formData[key] = value;
  }

  onSubmit() {
    if (this.form?.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.loading = true;
    this.UserService.update(this.formData)
      .then((response) => {
        toastSuccess(this.$translate.instant('profile.update-email-success'));
        if (this.UserSession.user.dashboard_settings?.mfa?.enabled) {
          toastSuccess(this.$translate.instant('profile.update-email-success-2fa'));
        }
        this.UserSession.update('user', response.user);
        this.UserSession.update('session', response.session);
        this.Auth.setCredentials(response.session.session_token);
        this.error = '';
        this.MixpanelService.track('Account - Profile - Email');
        this.esClose();
      })
      .catch((reason) => {
        this.error =
          (reason.data && reason.data.status) || this.translations['profile.update-email-failed'];
      })
      .finally(() => {
        this.loading = false;
      });
  }
}

const ChangeEmailModalComponent: ng.IComponentOptions = {
  controller: ChangeEmailModal,
  template,
  bindings: {
    esClose: '&',
  },
};

export { ChangeEmailModalComponent };
