import { toastError, toastSuccess } from '@app/components/Toastify';
import { useUserUpdateMutation } from '@app/hooks/mutations/useUserUpdateMutation';
import { useGetNotificationSettingsQuery } from '@app/hooks/queries/user/useGetNotificationSettingsQuery';
import { openEmailPreviewModal } from '@app/pages/profile/components/modals/EmailPreviewModal';
import { EmailPreviewModalType } from '@app/pages/profile/components/modals/EmailPreviewModal/types';
import MixpanelService from '@app/utils/mixpanel.service';
import { IUser } from '@typings/user-session';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  ToggleButton,
  ToggleButtonOption,
} from 'easyship-components';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface UpdateUserResponse {
  user: IUser;
}

export function CourierSurveyCard() {
  const { formatMessage } = useIntl();

  const { data: notificationSettings } = useGetNotificationSettingsQuery();
  const { mutateAsync: updateUser, isLoading } = useUserUpdateMutation();

  const [isCourierSurveyEnabled, setIsCourierSurveyEnabled] = useState(false);

  useEffect(() => {
    if (notificationSettings?.receiveSenderSurvey) {
      setIsCourierSurveyEnabled(notificationSettings?.receiveSenderSurvey);
    }
  }, [notificationSettings?.receiveSenderSurvey]);

  const toggleOptions: [ToggleButtonOption<boolean>, ToggleButtonOption<boolean>] = [
    {
      label: formatMessage({ id: 'global.no' }),
      value: false,
    },
    {
      label: formatMessage({ id: 'global.yes' }),
      value: true,
    },
  ];

  const handleUpdateUser = useCallback(
    async (value: boolean): Promise<UpdateUserResponse> => {
      return updateUser({
        notificationSettings: {
          receiveSenderSurvey: value,
        },
      });
    },
    [updateUser]
  );

  async function handleToggleChange(value: boolean) {
    if (value === isCourierSurveyEnabled) return;

    setIsCourierSurveyEnabled(value);
    try {
      const response = await handleUpdateUser(value);
      if (response?.user) {
        toastSuccess(formatMessage({ id: 'profile.update-success' }));
      }
      MixpanelService.track(`Account - Profile - Client Survey Notification`, {
        selection: value,
      });
    } catch (error) {
      setIsCourierSurveyEnabled(!value);
      toastError(error);
    }
  }

  return (
    <Card variant="normal">
      <CardHeader divider title={formatMessage({ id: 'profile.courier-survey-title' })} />
      <CardContent>
        <div>
          <div className="flex items-center justify-between gap-2">
            <div>
              <span className="mb-0 text-base text-ink-500">
                <FormattedMessage id="profile.courier-survey-content" />{' '}
              </span>
              <Button
                onClick={() =>
                  openEmailPreviewModal({
                    type: EmailPreviewModalType.SenderSurvey,
                  })
                }
                flat
                className="gap-0 p-0 text-base font-bold text-blue-500 normal-case cursor-pointer contents hover:text-blue-700 hover:bg-transparent disabled:text-sky-700"
              >
                <FormattedMessage id="global.preview" />
              </Button>
            </div>

            <ToggleButton<boolean>
              disabled={isLoading}
              options={toggleOptions}
              onChange={handleToggleChange}
              value={isCourierSurveyEnabled}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
