import axios from 'axios';
import { RequestFeaturePayloadProps, UpdateUserProps } from '@/api/user/types';
import { API } from '@/constants/api';
import { camelToSnakeCase } from '@/utils/snakeCamelTransform';
import { ISessionData, IUser } from '@typings/user-session';

export const requestFeature = async ({
  userId,
  payload,
}: { userId: string } & { payload: RequestFeaturePayloadProps }) => {
  const result = await axios.post(`${API.endpoint}/users/${userId}/feedback`, payload);
  return result.data;
};

export const updateUser = async ({
  userId,
  payload,
  companyId,
}: UpdateUserProps): Promise<{
  user: IUser;
}> => {
  const data = {
    user: {
      ...camelToSnakeCase(payload),
    },
  };
  return (
    (
      await axios.patch(`${API.endpoint}/users/${userId}?company_id=${companyId}`, {
        ...data,
      })
    )?.data || ({} as { user: IUser })
  );
};

export const fetchUserSessionData = async () => {
  const result = await axios.get(`${API.endpoint}/users/get_current_user`);
  return result.data as ISessionData;
};

export const getNotificationSettings = async (userId: string) => {
  const result = await axios.get(`${API.endpoint}/users/${userId}/notification_settings`);
  return result.data;
};
